.background {
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
}

.valueAdd {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
}

.icon {
  margin-bottom: 8px;
}

.title {
  margin-bottom: 8px;
}

.textContainer {
  text-align: center;
}

@media (min-width: 800px) {
  .container {
    flex-direction: row;
  }
}
