.content {
  display: flex;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
}

.button {
  border-radius: 50px;
  overflow: hidden;
}

.vehicleSelectedWrapper {
  width: 100%;
  display: flex;
  align-items: center;
}

.selectedCustomerProjectText {
  flex-grow: 1;
  text-align: left;
  border-right: 2px solid black;
  padding-right: 1rem;
}

.selectedCustomerProjectContent {
  display: flex;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
}

@media (min-width: 310px) and (max-width: 420px) {
  .content {
    max-width: 275px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: clip;
    display: block;
  }

  .selectedCustomerProjectContent {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: clip;
    display: block;
  }
}
