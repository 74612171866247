.section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media (min-width: 1024px) {
  .section {
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    grid-template-columns: repeat(var(--columnCount), minmax(0, 1fr));
  }
}

.title {
  text-align: center;
}

.block {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
}

.fillHeight {
  height: 100%;
}

.productCard {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.listRepresentatives {
  display: flex;
  gap: 0.25rem;
}

.cartBlock {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}

.cartItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-basis: calc(50% - 2rem);
  flex-grow: 2;
  position: relative;
  padding: 0.5rem;
}

.cartItemTitle {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-align: center;
}

.noWrap {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-align: center;
}

.signupCard {
  height: 100%;

  img {
    object-position: initial !important;
  }
}

.eventCard {
  display: flex;
  padding: 0.5rem 0.75rem;
  align-items: center;
  position: relative;
}

.eventTitle {
  text-align: center;
}

.dateContainer {
  border: 1px solid lightgrey;
  border-radius: 4px;
  padding: 0rem 1rem;
  text-align: center;
}

.description {
  text-wrap: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.descriptionContainer {
  overflow-x: hidden;
  margin-left: 0.5rem;
}

.appLink {
  font-size: 0.875rem;
  font-weight: bold;
}

.svg {
  align-items: right;
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
}

.inLineSvg {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.impressionInner {
  height: calc(100% - 2rem);
}

.impressionInnerCard {
  height: calc(100%);
}

.orderTitle {
  padding: 0.5rem;
}

.lineitems {
  display: flex;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
  gap: 0.5rem;
  align-items: center;
}

@media (min-width: 670px) {
  .eventTitle {
    display: flex;
    align-items: center;
    gap: 1rem;
    text-align: unset;
  }
}
