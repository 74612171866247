.container {
  align-self: center;
  height: 100%;
  margin: 1rem 0 1rem 0;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.text {
  text-align: center;
  margin-bottom: 1rem;
}

.img {
  height: auto;
  width: 75%;
  margin-bottom: 1rem;
}

.button {
  width: 100%;
}

@media (min-width: 550px) {
  .container {
    width: 80%;
  }
  .img {
    width: 70%;
  }
}

@media (min-width: 750px) {
  .container {
    width: 75%;
  }
  .img {
    width: 65%;
  }
}

@media (min-width: 1024px) {
  .container {
    width: 100%;
  }
  .img {
    width: 70%;
  }

  .button {
    width: 83%;
  }
}
