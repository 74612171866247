.heroBlock {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  position: relative;
  z-index: 1;
  height: 252px;
}

.defaultHeroBlock {
  height: 350px;
}

.heroImage {
  object-fit: cover;
}

.heroImageWrapper,
.raceImageWrapper {
  background-color: black;
  position: absolute;
  height: 252px;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
  color: transparent;
  overflow: hidden;
  z-index: -5;
}

// .gradient {
//   background: linear-gradient(
//     to bottom,
//     rgba(0, 0, 0, 0.05) 0%,
//     rgb(0, 0, 0) 100%
//   );
//   z-index: -3;
//   inset: 0;
//   width: 100%;
//   position: absolute;
// }

.defaultImageWrapper {
  height: 350px;
  display: flex;
  justify-content: center;
}

@media (min-width: 670px) {
  .heroBlock {
    flex-direction: column-reverse;
    height: 380px;
  }

  .raceImageWrapper {
    height: 380px;
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
    width: 150%;
  }

  .heroImageWrapper {
    height: 380px;
  }
}

@media (min-width: 1900px) {
  .raceImageWrapper {
    height: 380px;
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
    width: 100%;
  }
}

@media (min-width: 310px) and (max-width: 420px) {
  .raceImageWrapper {
    right: 0;
    left: auto;
    width: 200%;
  }
}

@media (min-width: 420px) {
  .raceImageWrapper {
    right: 0;
    left: auto;
    width: 125%;
  }
}

.smallImageWrapper {
  height: 6rem;
}

.heroBlockSmall {
  height: 6rem;
}

.smallGarageBlock {
  display: flex;
  flex-direction: column;
}

.smallGarageBlockText {
  min-width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media (min-width: 1023px) {
  .smallGarageBlock {
    flex-direction: row;
  }
}

.spacer {
  display: none;
}

@media (min-width: 1024px) {
  .spacer {
    display: block;
    height: 1rem;
  }
}
