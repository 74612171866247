.container {
  display: none;
}

.title {
  margin-top: 80px;
  position: static;
  display: block;
}

.subtitle {
  display: block;
}

.gutters {
  background-color: transparent;
}

@media (min-width: 670px) {
  .container {
    display: block;
  }
}
