$cardWidth: 300px;

.card {
  display: flex;
  width: 336px;
}

.card_internal {
  display: flex;
}

.card_image {
  position: relative;
  width: 96px;
  height: 96px;
}

.card_content {
  padding: 0.75rem;
  width: calc(336px - 96px - 1.5rem);
  white-space: normal;

  display: flex;
  flex-direction: column;
}

.card_content_description {
  display: none;
}

.card_content_callout {
  display: flex;
  flex-direction: row-reverse;
  margin-top: auto;
}

.card_content_callout_svg {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  margin-top: 0.25rem;
}

@media (min-width: 768px) {
  .card {
    width: $cardWidth;
  }

  .card_internal {
    display: block;
  }

  .card_image {
    width: $cardWidth;
    height: 200px;
  }

  .card_content {
    padding: 1rem;
    width: calc($cardWidth - 2rem);
    display: block;
  }

  .card_content_description {
    display: block;
  }

  .card_content_callout {
    margin-top: 0.5rem;
  }
}
