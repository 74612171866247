.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.buttonContent {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.panel {
  width: 100%;
}
