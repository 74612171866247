.headingContainer {
  text-align: center;
  margin-bottom: 8px;
}

.title,
.titleMobile {
  --sizeMod: 2;
}

.verticalSpacing {
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}

@media (max-width: 400px) {
  .titleMobile {
    --sizeMod: 1.5;
  }
}

@media (min-width: 1335px) {
  .verticalSpacing {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}
