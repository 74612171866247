@media only screen and (max-width: 1000px) {
  .container {
    grid-template-columns: repeat(auto-fill, minmax(150px, 2fr));
  }

  .container {
    a {
      --sizeMod: 0.875;
      overflow: hidden;
    }

    a span {
      white-space: initial;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0.25rem;
      max-width: 100%;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}
