@import 'Styles/breakpoints.module.scss';
@import 'Styles/colors.module.scss';

.cardWrapper {
  display: none;
}

.title {
  margin-left: auto;
  margin-right: auto;
  --sizeMod: 1.5;
}

.addVehicleLink {
  display: none;
}

@media (min-width: 670px) {
  .ymmSelector {
    overflow: visible;
    width: 100%;
  }

  .cardWrapper {
    display: block;
  }

  .garageSelector {
    overflow: visible;
    width: 30rem;
  }

  .heroImage {
    background-image: var(--u);
    background-repeat: no-repeat;

    width: 100%;
    height: 390px;
    background-size: cover;
    background-position: center 40%;

    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }

  .title {
    margin-top: 80px;
    --sizeMod: 2;
  }

  .subtitle,
  .title {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .container {
    display: flex;
    align-items: flex-end;
    padding: 0 0.5rem 0.5rem 0.5rem;
    gap: 0.5rem;
    cursor: pointer;
  }

  .streetEmptyDropdown {
    label {
      p {
        opacity: 50%;
      }
    }
  }

  .ymmModalItem {
    width: 33%;
  }

  .rtDropdownItem {
    width: 75%;
  }

  .engineDropdown {
    width: 50%;
    white-space: nowrap;
  }

  .garageDropdown {
    flex: 1 1 auto;
    width: 70%;
  }

  .addVehicleLink {
    margin-top: 0.5rem;
    display: block;
    width: 35%;
  }

  .buttonText {
    margin: 0px;
    padding: 0px;
    white-space: nowrap;
  }

  .modalPanel {
    border-radius: 0 0 0.5rem 0.5rem;
    padding: 0 10px 15px;
  }

  .addRaceVehicleButton {
    width: 50%;
  }

  .raceTypes {
    width: 175%;
    flex: auto;
    margin-right: 10px;
    margin-left: 0px;
  }
}

@include tabletMediaQuery {
  .garageDropdown {
    width: 60%;
  }
}
