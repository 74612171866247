@import 'Clutch/Styles/breakpoints.module.scss';

.emailNewsLetterContainer {
  display: flex;
  flex-direction: column;
  padding: 1rem 0.5rem;
  position: relative;
  overflow: hidden;
  max-width: 1800px;
  margin-left: auto;
  margin-right: auto;
}

.wheel {
  opacity: 0.5;
  position: absolute;
  left: 0px;
  top: -45%;
  width: 350px;
  height: 350px;
}

.descriptionContainer {
  text-align: center;
  margin-bottom: 1rem;
  z-index: 1;
}

.descriptionMobile {
  display: block;
}

.description {
  display: none;
}

.signUpContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

.signUpButton {
  margin-bottom: 1rem;
}

.disclaimer {
  text-align: center;
}

.signUpSizingContainer {
  padding: 0;
}

@media (min-width: 470px) {
  .signUpButton {
    width: 50%;
  }
}

@media (min-width: 1335px) {
  .descriptionMobile {
    display: none;
  }
  .description {
    display: block;
  }
  .descriptionContainer {
    flex: 1.5;
    margin-left: 6rem;
    text-align: left;
  }
  .signUpContainer {
    flex: 1;
    margin-right: 2rem;
  }
  .emailNewsLetterContainer {
    flex-direction: row;
  }
  .signUpButton {
    margin-bottom: auto;
    margin-top: auto;
    width: 100%;
  }
  .signUpSizingContainer {
    padding: 0 8rem;
  }

  .wheel {
    left: 0px;
    top: -45%;
    width: 250px;
    height: 250px;
  }
}
